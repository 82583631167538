import { excludeAbortError } from '@app/errors/AbortError'

import { setAnnouncementFilter } from '@app/packages/announcementsFilter/actions.descriptors'

import {
  createAnnouncementDescriptor,
  fetchAnnouncementDescriptor,
  getAnnouncementsSearchDescriptor,
  getAnnouncementsSearchNextDescriptor,
  getAnnouncementsUpcomingDescriptor,
} from '@app/store/actions/announcement.descriptors'
import { beforeNavigate } from '@app/store/actions/router.descriptors'
import { createReducer } from '@app/store/toolkit'
import { AnnouncementsMetaState } from '@app/store/types/announcements'

export default createReducer<AnnouncementsMetaState>(
  {
    fetched: false,
    loading: null,
    detailsLoading: false,
    per_page: 10,
    total: 0,
    total_pages: 0,
    filter: {},
    hasMore: false,
    cursor: null,
  },
  builder => {
    builder.addCase(beforeNavigate.shape, (state, action) => {
      if (!action.payload.initial) {
        let fetched = state.fetched
        if (action.payload.state.route.event_id === 'announcements' || action.payload.state.route.event_id === 'parent_announcements') {
          fetched = false
        }
        return { ...state, error: null, fetched }
      }
      return state
    })
    builder.addCase(setAnnouncementFilter.shape, (state, action) => {
      return { ...state, filter: action.payload }
    })
    builder.addCase(createAnnouncementDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, error: null }
    })
    builder.addCase(getAnnouncementsSearchDescriptor.shapes.pending, (state, action) => {
      return { ...state, loading: 'start', error: null, cursor: null, per_page: action.meta.query.per_page }
    })
    builder.addCase(getAnnouncementsSearchDescriptor.shapes.fulfilled, (state, action) => {
      return {
        ...state,
        fetched: true,
        loading: null,
        error: null,
        total: action.payload.meta.total,
        total_pages: action.payload.meta.total_pages,
        cursor: action.payload.meta.cursor,
        hasMore: action.payload.data.length === state.per_page,
      }
    })
    builder.addCase(getAnnouncementsSearchDescriptor.shapes.rejected, (state, _action) => {
      return { ...state, loading: null }
    })
    builder.addCase(getAnnouncementsSearchNextDescriptor.shapes.fulfilled, (state, action) => {
      return {
        ...state,
        fetched: true,
        loading: null,
        cursor: action.payload.meta.cursor,
        hasMore: action.payload.data.length === state.per_page,
      }
    })
    builder.addCase(getAnnouncementsSearchNextDescriptor.shapes.pending, (state, _action) => {
      return { ...state, loading: 'next' }
    })
    builder.addCase(getAnnouncementsSearchNextDescriptor.shapes.rejected, (state, action) => {
      return { ...state, loading: null, error: excludeAbortError(action.payload) }
    })
    builder.addCase(getAnnouncementsUpcomingDescriptor.shapes.pending, (state, _action) => {
      return { ...state, loading: 'start' }
    })
    builder.addCase(getAnnouncementsUpcomingDescriptor.shapes.fulfilled, (state, action) => {
      return {
        ...state,
        total: action.payload.data.length,
        fetched: true,
        loading: null,
      }
    })
    builder.addCase(getAnnouncementsUpcomingDescriptor.shapes.rejected, (state, _action) => {
      return { ...state, loading: null }
    })
    builder.addCase(fetchAnnouncementDescriptor.shapes.pending, (state, _action) => {
      return { ...state, detailsLoading: true }
    })
    builder.addCase(fetchAnnouncementDescriptor.shapes.fulfilled, (state, _action) => {
      return { ...state, detailsLoading: false }
    })
    builder.addCase(fetchAnnouncementDescriptor.shapes.rejected, (state, _action) => {
      return { ...state, detailsLoading: false }
    })
  }
)
