import pickBy from 'lodash/pickBy'

import { compact } from '@app/utils/compactV2Entity'
import { normalize } from '@app/utils/normalizer'

import {
  getAcademyCourseByIdDescriptor,
  getAcademyCoursesDescriptor,
  getAcademyLessonsByIdDescriptor,
  getAcademyLessonsDescriptor,
} from '@app/store/actions/academy.descriptors'
import {
  fetchAnnouncementDescriptor,
  getAnnouncementsByQueryDescriptor,
  getAnnouncementsSearchDescriptor,
  getAnnouncementsSearchNextDescriptor,
  getAnnouncementsUpcomingDescriptor,
} from '@app/store/actions/announcement.descriptors'
import { fetchAnnouncementResponseDescriptor } from '@app/store/actions/announcementResponse.descriptors'
import { postIgnoredUserDescriptor } from '@app/store/actions/api/ignored.descriptors'
import { getDirectAnnouncementsIdDescriptor } from '@app/store/actions/directAnnouncement.descriptors'
import { firebaseBatch } from '@app/store/actions/firebase.descriptors'
import {
  addToFavoritesDescriptor,
  deleteFromFavoritesDescriptor,
  getFavoritesDescriptor,
  postEmailCodeVerificationDescriptor,
  postEmailTokenVerificationDescriptor,
} from '@app/store/actions/profile.descriptors'
import {
  cancelRequestDescriptor,
  createRequestFromAnnouncementResponseDescriptor,
  fetchRequestDescriptor,
  fetchRequestsDescriptor,
  getRequestsWithDebtsDescriptor,
  rejectRequestDescriptor,
} from '@app/store/actions/request.descriptors'
import { getPublicParentReviewsDescriptor } from '@app/store/actions/reviews.descriptors'
import { fetchTrustedSittersDescriptor, fetchTrustingParentsDescriptor } from '@app/store/actions/trusted.descriptors'
import { getUsersByIdsDescriptor, profileRequestActionDescriptor, userRequestActionDescriptor } from '@app/store/actions/user.descriptors'
import { createReducer } from '@app/store/toolkit'
import { StoreUser, UsersModelsState } from '@app/store/types/users'

import { getSitterSearchDescriptor } from '@app/routes/Search/actions.descriptors'

export default createReducer<UsersModelsState>({}, builder => {
  builder.addCases(
    [
      userRequestActionDescriptor.shapes.fulfilled,
      getUsersByIdsDescriptor.shapes.fulfilled,
      profileRequestActionDescriptor.shapes.fulfilled,
      getPublicParentReviewsDescriptor.shapes.fulfilled,

      getAcademyLessonsDescriptor.shapes.fulfilled,
      getAcademyLessonsByIdDescriptor.shapes.fulfilled,
      getAcademyCoursesDescriptor.shapes.fulfilled,
      getAcademyCourseByIdDescriptor.shapes.fulfilled,

      postEmailCodeVerificationDescriptor.shapes.fulfilled,
      postEmailTokenVerificationDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const newState = { ...state }
      const { avatars: avatarsMap = {}, users = {} } = normalize(action.payload)
      for (const rawUser of Object.values(users)) {
        const avatars = rawUser?.relationships?.avatars?.data?.map(m => compact(avatarsMap[m.id])) ?? []
        const avatar_url = avatars.at(0)?.image.url ?? null
        const user: StoreUser = {
          ...newState[rawUser.id],
          ...pickBy(compact(rawUser), (v, k) => ((k as keyof StoreUser) === 'phone' ? !!v : true)),
          avatars,
          avatar_url,
          avatarsComplete: true,
        }

        newState[user.id] = user
        newState[user.token] = user
      }

      return newState
    }
  )

  builder.addCases([deleteFromFavoritesDescriptor.shapes.rejected, addToFavoritesDescriptor.shapes.pending], (state, action) => {
    const { user_id } = action.meta
    const model = state[user_id]
    if (!model) return state
    const newModel = { ...model, favorite: true }
    return {
      ...state,
      [newModel.id]: newModel,
      [newModel.token]: newModel,
    }
  })

  builder.addCases([addToFavoritesDescriptor.shapes.rejected, deleteFromFavoritesDescriptor.shapes.pending], (state, action) => {
    const { user_id } = action.meta
    const model = state[user_id]
    if (!model) return state
    const newModel = { ...model, favorite: false }
    return {
      ...state,
      [newModel.id]: newModel,
      [newModel.token]: newModel,
    }
  })

  builder.addCase(postIgnoredUserDescriptor.shapes.fulfilled, (state, action) => {
    const { user_id } = action.meta
    const model = state[user_id]
    if (!model) return state
    const newModel = { ...model, favorite: false }
    return {
      ...state,
      [newModel.id]: newModel,
      [newModel.token]: newModel,
    }
  })

  builder.addCase(firebaseBatch.shape, (state, action) => {
    const idPhoneMap = Object.values(action.payload.requests.models).reduce<{ [key: string]: string }>((c, r) => {
      if (r.attributes.parent.phone) {
        c[r.attributes.parent.id] = r.attributes.parent.phone
      }
      if (r.attributes.sitter.phone) {
        c[r.attributes.sitter.id] = r.attributes.sitter.phone
      }
      return c
    }, {})

    const pState: UsersModelsState = {}

    for (const [id, phone] of Object.entries(idPhoneMap)) {
      const user = state[id]
      if (!user) continue
      if (user.phone !== phone) {
        const model = { ...user, phone }
        pState[user.id] = model
        pState[user.token] = model
      }
    }

    if (Object.keys(pState).length) {
      return { ...state, ...pState }
    }

    return state
  })

  builder.addCases(
    [
      fetchTrustedSittersDescriptor.shapes.fulfilled,
      fetchTrustingParentsDescriptor.shapes.fulfilled,
      getSitterSearchDescriptor.shapes.fulfilled,
      getFavoritesDescriptor.shapes.fulfilled,

      cancelRequestDescriptor.shapes.fulfilled,
      rejectRequestDescriptor.shapes.fulfilled,
      getDirectAnnouncementsIdDescriptor.shapes.fulfilled,
      getRequestsWithDebtsDescriptor.shapes.fulfilled,

      fetchRequestsDescriptor.shapes.fulfilled,
      fetchRequestDescriptor.shapes.fulfilled,
      createRequestFromAnnouncementResponseDescriptor.shapes.fulfilled,
      fetchAnnouncementDescriptor.shapes.fulfilled,

      getAnnouncementsUpcomingDescriptor.shapes.fulfilled,
      getAnnouncementsSearchDescriptor.shapes.fulfilled,
      getAnnouncementsSearchNextDescriptor.shapes.fulfilled,
      fetchAnnouncementResponseDescriptor.shapes.fulfilled,
      getAnnouncementsByQueryDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const { users, avatars = {} } = normalize(action.payload)
      if (!users) return state

      const models = Object.values(users)
        .map((u): StoreUser => {
          const stateUser = state[u.id]
          const stateHasAvatars = stateUser && stateUser.avatarsComplete
          const dataHasAvatars = !!(u.relationships.avatars && u.relationships.avatars.data)
          const userAvatars = !u.relationships.avatars || !u.relationships.avatars.data ? [] : u.relationships.avatars.data.map(r => compact(avatars[r.id]))
          const avatar_url = userAvatars.length ? userAvatars[0].image.url : null
          const user: StoreUser = {
            ...state[u.id],
            ...pickBy(compact(u), (v, k) => ((k as keyof StoreUser) === 'phone' ? !!v : true)),
            ...(dataHasAvatars
              ? { avatars: userAvatars, avatar_url }
              : { avatars: (stateUser && stateUser.avatars) || [], avatar_url: (stateUser && stateUser.avatar_url) || null }),
            avatarsComplete: stateHasAvatars || dataHasAvatars,
          }
          return user
        })
        .reduce<UsersModelsState>((acc, u) => {
          acc[u.id] = u
          acc[u.token] = u
          return acc
        }, {})

      return {
        ...state,
        ...models,
      }
    }
  )
})
