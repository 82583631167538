import { asError } from './asError'
import { Result } from './Result'

export function tryJSONparse<T>(data?: string | null): Result<T, Error> {
  try {
    const value = JSON.parse(data || '')
    return Result.ok(value)
  } catch (e) {
    return Result.err(asError(e))
  }
}
