import type { ApiAnnouncement, ApiAnnouncementResponse, ApiAvatar, ApiLocation, ApiUser } from '@app/constants/ApiTypes/entities'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createPayloadlessAction } from '@app/store/toolkit'
import type { AnnouncementsMetaState } from '@app/store/types/announcements'

export const resetAnnouncementErrors = createPayloadlessAction('ANNOUNCEMENT_CREATE_RESET_ERRORS')

export type ANNOUNCEMENT_SEARCH_PAYLOAD = {
  data: ApiAnnouncement[]
  included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[]
  meta: { total: number; total_pages: number; current_page: number; cursor: string }
}

export const getAnnouncementsSearchDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_ANNOUNCEMENTS_SEARCH')
  .setShape<ANNOUNCEMENT_SEARCH_PAYLOAD, { query: Pick<AnnouncementsMetaState, 'per_page'> }>()

export const getMapAnnouncementsSearchDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_MAP_ANNOUNCEMENTS_SEARCH').setShape<
  {
    data: {
      type: ApiAnnouncement['type']
      id: ApiAnnouncement['id']
      relationships: {
        location: { data: { id: string } }
      }
    }[]
    included?: {
      type: ApiLocation['type']
      id: ApiLocation['id']
      attributes: {
        longitude: number
        latitude: number
      }
    }[]
    meta: { cursor: string }
  },
  { initial: boolean }
>()

export const getAnnouncementsSearchNextDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_ANNOUNCEMENTS_SEARCH_NEXT')
  .setShape<ANNOUNCEMENT_SEARCH_PAYLOAD>()

export const getAnnouncementsSearchCountDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_ANNOUNCEMENTS_SEARCH_COUNT').setShape<{ total: number }>()

export const getAnnouncementsUpcomingDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_ANNOUNCEMENTS_UPCOMING')
  .setShape<{ data: ApiAnnouncement[]; included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[] }>()

export const getAnnouncementsByQueryDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('GET_ANNOUNCEMENTS_BY_QUERY')
  .setShape<{ data: ApiAnnouncement[]; included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[] }>()

export const createAnnouncementDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('CREATE_ANNOUNCEMENT')
  .setShape<{ data: ApiAnnouncement; included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[] }>()

export const cancelAnnouncementDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('ANNOUNCEMENT_CANCEL')
  .setShape<{ data: ApiAnnouncement; included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[] }, { announcement_id: string }>()

export const announcementHideDescriptor = new ApiActionBuilderDescriptor().setLabel('ANNOUNCEMENT_HIDE').setShape<undefined, { announcement_id: string }>()

export const fetchAnnouncementDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('FETCH_ANNOUNCEMENT')
  .setShape<{ data: ApiAnnouncement; included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[] }, { announcement_id: string }>()

export const editAnnouncementDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('EDIT_ANNOUNCEMENT')
  .setShape<{ data: ApiAnnouncement; included?: (ApiUser | ApiLocation | ApiAvatar | ApiAnnouncementResponse)[] }>()
