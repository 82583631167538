import type Cookies from 'universal-cookie'

import { SessionData } from '@app/constants/StoreTypes'

import { createCookie } from './cookies'
import moment from './moment'
import { tryJSONparse } from './tryJSONparse'
import { wrapError } from './wrapError'

const COOKIE_NAME = 'kidsout_session'

const sessionCookie = createCookie<SessionData | null>(
  COOKIE_NAME,
  v => {
    const data = tryJSONparse<SessionData | null>(v).unwrapOr(null)
    if (!data) return data
    if (!data.access_token) return null
    if (data.supervisor && !data.supervisor.access_token) {
      delete data.supervisor
    }
    return data
  },
  v => JSON.stringify(v),
  () => ({ expires: moment().add(10, 'years').toDate(), httpOnly: false, sameSite: 'lax' })
)

export function clearSession(cookies: Cookies) {
  sessionCookie.rm(cookies)
}

async function setSession(cookies: Cookies, value: SessionData) {
  try {
    if (IS_BROWSER) {
      await fetch('/internal/set-cookie', {
        method: 'post',
        headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
        body: JSON.stringify({
          name: COOKIE_NAME,
          options: { expires: moment().add(10, 'years').toDate(), path: '/', httpOnly: false, overwrite: true, sameSite: 'Lax' },
          value: encodeURIComponent(JSON.stringify(value)),
        }),
      }).catch(() => {})

      const hasCookie = !!document.cookie.split(';').find(part => part.trim().substring(0, COOKIE_NAME.length) === COOKIE_NAME)
      if (hasCookie) return
    }

    sessionCookie.set(cookies, value)
  } catch (e) {
    throw wrapError(new Error('Cannot set session cookie'), e)
  }
}

export async function persistSession(cookies: Cookies, headers: Partial<SessionData> | null) {
  if (!headers) {
    clearSession(cookies)
    return
  }

  const oldval = sessionCookie.get(cookies)
  const newval = { ...oldval, ...headers }
  if (newval.access_token) await setSession(cookies, newval as SessionData)
}

export function extractSession(cookies: Cookies) {
  return sessionCookie.get(cookies)
}
